import React, { CSSProperties, PropsWithChildren } from 'react';
import cn from 'classnames';

import styles from './grid.module.scss';

export function Grid(
    props: PropsWithChildren<{
        tag?: string;
        style?: CSSProperties;
        className?: string;
        containerClassName?: string;
        id?: string;
    }>,
): JSX.Element {
    const { tag = 'div', className, containerClassName, children, ...restProps } = props;

    return React.createElement(
        tag,
        { className: cn(styles.grid, className), ...restProps },
        <div className={cn(styles.grid__container, containerClassName)}>{children}</div>,
    );
}
