import './aos.scss';

type AOSOptions = {
    once: boolean;
    offset: number;
    delay: number;
    easing: string;
    duration: number;
    animatedClassName: string;
    initClassName: string;
};

type ElementOptions = {
    position: {
        in: number;
    };
    options: {
        animated?: boolean;
        once: boolean;
        animatedClassNames: string[];
    };
};

export class AOS {
    private _options: AOSOptions = {
        once: true,
        offset: 120,
        delay: 0,
        easing: 'ease',
        duration: 400,
        animatedClassName: 'aos-animate',
        initClassName: 'aos-init',
    };
    private _elements: HTMLElement[] = [];
    private _map: Map<HTMLElement, ElementOptions> = new Map();

    private _onScroll = throttle(() => this.update(), 100);

    public constructor(elements: HTMLElement[]) {
        this._elements = elements;
        this._map = prepare(this._elements, this._options);

        document.body.setAttribute('data-aos-easing', this._options.easing);
        document.body.setAttribute('data-aos-duration', String(this._options.duration));
        document.body.setAttribute('data-aos-delay', String(this._options.delay));

        // /**
        //  * Refresh plugin on window resize or orientation change
        //  */
        // window.addEventListener(
        //     'resize',
        //     debounce(refresh, options.debounceDelay, true)
        // );

        // window.addEventListener(
        //     'orientationchange',
        //     debounce(refresh, options.debounceDelay, true)
        // );

        window.addEventListener('scroll', this._onScroll, { passive: true });
    }

    public destroy(): void {
        this._elements = [];
        this._map.clear();
        window.removeEventListener('scroll', this._onScroll);

        document.body.removeAttribute('data-aos-easing');
        document.body.removeAttribute('data-aos-duration');
        document.body.removeAttribute('data-aos-delay');
    }

    public update(): void {
        for (const [el, elementOptions] of this._map) {
            applyClasses(el, elementOptions, window.pageYOffset);
        }
    }
}

export function throttle(func: () => void, wait: number): () => void {
    let timeout: number | null = null;

    const later = () => {
        timeout = null;
        func();
    };

    return function () {
        if (!timeout) {
            timeout = window.setTimeout(later, wait);
        }
    };
}

export const getPositionIn = (el: HTMLElement, defaultOffset: number) => {
    const windowHeight = window.innerHeight;

    const additionalOffset = getAdditionalOffset(el, defaultOffset);
    const anchorEl = getAnchor(el);

    const triggerPoint = getOffset(anchorEl).top - windowHeight;

    return triggerPoint + additionalOffset;
};

export const getPositionOut = (el: HTMLElement, defaultOffset: number) => {
    // const windowHeight = window.innerHeight;
    const additionalOffset = getAdditionalOffset(el, defaultOffset);
    const anchorEl = getAnchor(el);

    const elementOffsetTop = getOffset(anchorEl).top;

    return elementOffsetTop + anchorEl.offsetHeight - additionalOffset;
};

const getOffset = (el: HTMLElement) => {
    let left = 0;
    let top = 0;

    while (el && !isNaN(el.offsetLeft) && !isNaN(el.offsetTop)) {
        left += el.offsetLeft - (el.tagName != 'BODY' ? el.scrollLeft : 0);
        top += el.offsetTop - (el.tagName != 'BODY' ? el.scrollTop : 0);
        el = el.offsetParent as HTMLElement;
    }

    return {
        top,
        left,
    };
};

const getAnchor = (el: HTMLElement) => {
    const anchor = el.getAttribute('data-aos-anchor');
    if (anchor === null) {
        return el;
    }

    return document.querySelector<HTMLElement>(anchor) ?? el;
};

const getAdditionalOffset = (el: HTMLElement, defaultOffset: number) =>
    Number(el.getAttribute('data-aos-offset')) ?? defaultOffset;

const prepare = (elements: HTMLElement[], options: AOSOptions) => {
    const map = new Map<HTMLElement, ElementOptions>();

    elements.forEach((el) => {
        //
        el.classList.toggle(options.initClassName, true);

        map.set(el, {
            position: {
                in: getPositionIn(el, options.offset),
                // out: mirror && getPositionOut(el.node, options.offset),
            },
            options: {
                once: options.once,
                animatedClassNames: [options.animatedClassName],
            },
        });
    });

    return map;
};

const applyClasses = (el: HTMLElement, elementOptions: ElementOptions, top: number) => {
    const { position, options } = elementOptions;

    if (top >= position.in) {
        if (options.animated) {
            return;
        }

        el.classList.add(...options.animatedClassNames);
        options.animated = true;
    } else if (options.animated && !options.once) {
        if (!options.animated) {
            return;
        }

        el.classList.remove(...options.animatedClassNames);
        options.animated = false;
    }
};
