import React from 'react';

export function FacebookIcon(): JSX.Element {
    return (
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M20.49 27.86H17V19.8h-1.74v-2.78h1.74v-1.67c0-2.27.98-3.62 3.77-3.62h2.32v2.79h-1.45c-1.09 0-1.16.38-1.16 1.11v1.4h2.63l-.31 2.77h-2.32v8.07z"
                fill="currentColor"
            />
        </svg>
    );
}
