import React, { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import { useMedia } from 'react-use';
import cn from 'classnames';

import styles from './cursor.module.scss';

export function Cursor(): JSX.Element | null {
    const ref = useRef<HTMLDivElement>(null);
    const propsRef = useRef({ x: -100, y: -100, hover: false });
    const rafIdRef = useRef(-1);

    const showCursor = useMedia('(min-width: 1300px)', false);

    useEffect(() => {
        if (!showCursor) {
            return;
        }

        const onMouseMove = (e: MouseEvent) => {
            propsRef.current.x = e.clientX;
            propsRef.current.y = e.clientY;
            propsRef.current.hover = (e.target as any)?.closest('a,button,input,label') !== null;

            // propsRef.current.x = 100;
            // propsRef.current.y = 100;
            // propsRef.current.hover = true;
        };

        document.addEventListener('mousemove', onMouseMove);
        document.body.classList.toggle('disable-native-cursor', true);

        const render = () => {
            const { current: el } = ref;
            if (el !== null) {
                const { x, y, hover } = propsRef.current;
                const size = hover ? 50 : 8;

                el.classList.toggle(styles.cursorHover, hover);
                el.style.transform = `translate(${x}px, ${y}px)`;
                el.style.left = `${-size / 2}px`;
                el.style.top = `${-size / 2}px`;
                el.style.width = `${size}px`;
                el.style.height = `${size}px`;
            }

            rafIdRef.current = requestAnimationFrame(render);
        };
        rafIdRef.current = requestAnimationFrame(render);

        return () => {
            document.body.classList.toggle('disable-native-cursor', false);
            document.removeEventListener('mousemove', onMouseMove);
            cancelAnimationFrame(rafIdRef.current);
        };
    });

    if (!showCursor) {
        return null;
    }

    return ReactDOM.createPortal(<div ref={ref} className={cn(styles.cursor, styles.cursorSmall)} />, document.body);
}
