import React, { CSSProperties } from 'react';
import cn from 'classnames';

import { fbLink, liLink, twLink } from 'constants/constants';

import { FacebookIcon } from 'components/svg/facebook';
import { LinkedinIcon } from 'components/svg/linkedin';
import { TwitterIcon } from 'components/svg/twitter';

import styles from './social-links.module.scss';

type SocialLinksProps = {
    color: 'light' | 'dark';
    style?: CSSProperties;
    className?: string;
};

export function SocialLinks(props: SocialLinksProps): JSX.Element {
    const { color, className, ...restProps } = props;
    return (
        <div
            className={cn(
                styles.socialLinks,
                {
                    [styles.socialLinksLight]: color === 'light',
                    [styles.socialLinksDark]: color === 'dark',
                },
                className,
            )}
            {...restProps}
        >
            <a href={fbLink}>
                <FacebookIcon />
            </a>
            <a href={twLink}>
                <TwitterIcon />
            </a>
            <a href={liLink}>
                <LinkedinIcon />
            </a>
        </div>
    );
}
