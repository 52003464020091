import React from 'react';
import cn from 'classnames';

import { useModal } from 'components/modal/modal';

import styles from './menu-button.module.scss';

type MenuButtonProps = {
    type: 'left' | 'right';
};

export function MenuButton(props: MenuButtonProps): JSX.Element {
    const { type } = props;

    const { modal, setModal } = useModal();

    const isActive = modal === 'mainMenu';

    return (
        <button
            id="menu-button"
            className={cn(styles.menuButton, { [styles.active]: isActive, [styles.right]: type === 'right' })}
            onClick={() => setModal(isActive ? null : 'mainMenu')}
            title={isActive ? 'Hide menu' : 'Show menu'}
        />
    );
}
