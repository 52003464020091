import React from 'react';
import cn from 'classnames';
import { Link } from 'gatsby';
import { useMedia } from 'react-use';
import { useModal } from 'components/modal/modal';
import { getOffsetTop } from 'utils/get-offset-top';
import styles from './header-menu.module.scss';

export function HeaderMenu(): JSX.Element {
    const isLg = useMedia('(min-width: 600px)', false);

    const { modal, setModal } = useModal();
    const isActive = modal === 'mainMenu';

    if (isLg) {
        return (
            <nav className={styles.headerMenu}>
                <MenuLink href="/projects" isLink>Projects</MenuLink>
                <MenuLink href="/history" isLink>History</MenuLink>
                <MenuLink href="/team" isLink>Team</MenuLink>
                <MenuLink href="/careers" isLink>Careers</MenuLink>
                <MenuLink href="#blog">Blog</MenuLink>
                <MenuLink href="#contacts">Contacts</MenuLink>
            </nav>
        );
    }

    return (
        <MenuButton
            onPress={() => setModal('mainMenu')}
            isActive={isActive}
        />
    );
}

type MenuLinkProps = {
    children: any;
    href: string;
    isLink?: boolean;
    title?: string;
};

const MenuLink = React.memo(function MenuLink(props: MenuLinkProps) {
    const onClick = React.useCallback((event) => {
        if (props.isLink) return;

        event.preventDefault();

        const hash = props.href.replace(/^\/|\/$/g, '');

        try {
            const el = document.querySelector<HTMLElement>(hash);
            if (el === null) {
                return;
            }

            window.scroll({ top: getOffsetTop(el) - 110, behavior: 'smooth' });
        } catch (err) {
            console.error(err);
        }
    }, [props.href]);

    return <Link
        to={props.href}
        className={styles.headerMenu__item}
        onClick={onClick}
    >
        {props.children}
    </Link>;
});

type MenuButtonProps = {
    onPress?: () => void;
    isActive?: boolean;
    type?: 'right' | 'left';
};

function MenuButton(props: MenuButtonProps) {
    const { onPress, isActive, type } = props;

    const buttonClasses = cn(
        styles.menuButton,
        {
            [styles.active]: isActive,
            [styles.right]: type === 'right',
        },
    );

    return <button
        id="menu-button"
        className={buttonClasses}
        onClick={onPress}
        title={isActive ? 'Hide menu' : 'Show menu'}
    />;
}
