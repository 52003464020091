import React from 'react';

type GTMTagProps = {
    code: string;
};

export function GTMTag(props: GTMTagProps): JSX.Element {
    const { code } = props;

    const gtmTagContent = `
    document.addEventListener('DOMContentLoaded', function(){
        setTimeout(function(){
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=!0;j.src=
                'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','${code}');
        }, 3000);
    });`;

    return (
        <>
            <script async defer dangerouslySetInnerHTML={{ __html: gtmTagContent }} />
            <noscript>
                <iframe src={`https://www.googletagmanager.com/ns.html?id=${code}`}
                        style={{ display: 'none', visibility: 'hidden', height: 0, width: 0 }}
                />
            </noscript>
        </>
    );
}