export const getOffsetTop = (el: HTMLElement | null) => {
    let anchorEl = el;
    let offset = 0;

    while (anchorEl) {
        offset += anchorEl.offsetTop;
        anchorEl = anchorEl.offsetParent as HTMLElement;
    }

    return offset;
};
