import React, { useState } from 'react';
import { useCookie } from 'react-use';

import { cookieBannerWasSeen, cookieEnableAnalytics, cookieEnableTargeting } from 'constants/constants';

import styles from './cookie-banner.module.scss';

// eslint-disable-next-line @typescript-eslint/no-implied-eval
const isBrowser = new Function('try {return this===window;}catch(e){ return false;}');

export function CookieBanner(): JSX.Element | null {
    const [bannerCookie, updateBannerCookie] = useCookie(cookieBannerWasSeen);
    const [isBannerHidden, hideBanner] = useState(Boolean(Number(bannerCookie)));

    const [showSettings, setShowSettings] = useState(false);
    const [analyticsCookie, updateAnalyticsCookie] = useCookie(cookieEnableAnalytics);
    const [targetingCookie, updateTargetingCookie] = useCookie(cookieEnableTargeting);

    const [analyticsValue, setAnalyticsValue] = useState(Boolean(Number(analyticsCookie)));
    const [targetingValue, setTargetingValue] = useState(Boolean(Number(targetingCookie)));

    const onClose = () => {
        updateBannerCookie('1', { expires: 365 * 24 * 60 * 60 });
        hideBanner(true);
    };

    if (!isBrowser() || isBannerHidden) {
        return null;
    }

    return (
        <div
            id="cookie-banner"
            data-cookie-banner
            className={styles.cookieBanner}
            style={{ maxHeight: showSettings ? 'calc(100vh - 30px)' : undefined }}
        >
            {!showSettings ? (
                <div className={styles.cookieBanner__body}>
                    <h6>Your Cookie Settings</h6>
                    <p>
                        We use cookies to ensure high quality standards, to optimize website functionality, and to
                        display personalized content. By clicking "Yes, I agree", you consent to this use of cookies.
                        Current cookie settings can be changed by clicking on "Change settings". Read our{' '}
                        <a href="#cookie-policy">Cookie Policy</a> to find out more.
                    </p>
                    <div>
                        <button onClick={onClose}>Accept</button>
                        <button onClick={() => setShowSettings(true)}>Change Settings</button>
                    </div>
                </div>
            ) : (
                <form
                    className={styles.cookieBanner__body}
                    onSubmit={(e: React.FormEvent) => {
                        e.preventDefault();
                        updateAnalyticsCookie(String(Number(analyticsValue)), { expires: 365 * 24 * 60 * 60 });
                        updateTargetingCookie(String(Number(targetingValue)), { expires: 365 * 24 * 60 * 60 });
                        onClose();
                    }}
                >
                    <h6>Your Cookie Settings</h6>
                    <p>
                        We use cookies to enable us to offer you the best possible service. These can be categorized
                        into cookies that are necessary to run this website and those that are used for analytical
                        reasons or to display personalized content. It is up to you to decide which cookies should be
                        allowed. However, you should know that blocking some types of cookies may impact your experience
                        on our website and the services we are able to offer.
                    </p>
                    <span>Functional</span>
                    <p>
                        These cookies are necessary to run the core functionalities of this website (read more detailed
                        information on the performance, authentication and functionality cookies in our Cookie Policy).
                        The website cannot function properly without these cookies, which can only be disabled by
                        changing your browser preferences.
                    </p>

                    <label>
                        Analytics{' '}
                        <input
                            name="value-a"
                            type="checkbox"
                            checked={analyticsValue}
                            onChange={() => setAnalyticsValue(!analyticsValue)}
                        />
                    </label>
                    <p>
                        These cookies help us to understand how you interact with the website by collecting anonymous
                        information about how visitors use our website.
                    </p>

                    <label>
                        Targeting{' '}
                        <input
                            name="value-t"
                            type="checkbox"
                            checked={targetingValue}
                            onChange={() => setTargetingValue(!targetingValue)}
                        />
                    </label>
                    <p>These cookies are used to display personalized content matching your interests.</p>

                    <div>
                        <button>Confirm & Save</button>
                    </div>
                </form>
            )}
        </div>
    );
}
