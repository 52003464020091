import React from 'react';
import { useMedia } from 'react-use';
import { graphql, Link, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';

import { getOffsetTop } from 'utils/get-offset-top';
import { twLink } from 'constants/constants';

import { Aside } from 'components/aside/aside';
import { Grid } from 'components/grid/grid';
import { Header } from 'components/header/header';
import { Logo } from 'components/logo/logo';
import { MenuButton } from 'components/menu-button/menu-button';
import { useModal } from 'components/modal/modal';
import { SocialLinks } from 'components/social-links/social-links';

import styles from './main-menu.module.scss';

import { Layout } from 'layouts/base';

export function MainMenu(): JSX.Element {
    const data = useStaticQuery(imgQuery);

    const showAside = useMedia('(min-width: 1300px)', true);

    const { setModal } = useModal();

    const getHandler = (id: string) => (e: React.MouseEvent) => {
        if (window.location.pathname !== '/') {
            return;
        }

        const targetEl = document.getElementById(id);
        if (targetEl === null) {
            return;
        }

        e.preventDefault();
        window.history.pushState({}, '', `#${id}`);
        window.scroll({ top: getOffsetTop(targetEl) - 100, behavior: 'smooth' });
    };

    return (
        <div className={styles.mainMenu} onClick={(e: React.MouseEvent) => e.stopPropagation()}>
            <div className={styles.mainMenu__background}>
                <div
                    className={styles.mainMenu__backgroundLayer}
                    style={{
                        backgroundImage: `url(${require('../../img/circles-md.svg')})`,
                        backgroundSize: '1368px 1374px',
                        backgroundPosition: 'center top 40px',
                        backgroundRepeat: 'no-repeat',
                    }}
                />
                <div className={styles.mainMenu__backgroundLayer}>
                    <Img fluid={data.clouds.childImageSharp.fluid} />
                </div>
            </div>
            <Layout>
                <Header>
                    <Link to="/">
                        <Logo />
                    </Link>

                    {!showAside && <MenuButton type="right" />}
                </Header>

                {showAside && <Aside />}

                <Grid>
                    <div className={styles.mainMenu__body}>
                        <div className={styles.mainMenu__menu} onClick={() => setModal(null)}>
                            <Link to="/" onClick={getHandler('home')}>
                                Home
                            </Link>
                            <Link to="/projects">
                                Projects
                            </Link>
                            <Link to="/history">
                                History
                            </Link>
                            <Link to="/team">
                                Team
                            </Link>
                            <Link to="/careers">
                                Careers
                            </Link>
                            <Link to="/#blog" onClick={getHandler('blog')}>
                                Blog
                            </Link>
                            <a href="#contacts" onClick={getHandler('contacts')}>
                                Contacts
                            </a>
                        </div>

                        <div className={styles.mainMenu__links}>
                            <div className={styles.mainMenu__link}>
                                <span>write an email</span>
                                <a href="mailto:letstalk@p1k.org">letstalk@p1k.org</a>
                            </div>
                            <div className={styles.mainMenu__link}>
                                <span>HR</span>
                                <a href="mailto:hr@p1k.org">hr@p1k.org</a>
                            </div>
                        </div>

                        <div className={styles.mainMenu__contacts}>
                            <div className={styles.mainMenu__contact}>
                                <span>collaborate</span>
                                <a href="mailto:letscollaborate@p1k.org">→ letscollaborate@p1k.org</a>
                            </div>
                            <div className={styles.mainMenu__contact}>
                                <span>follow</span>
                                <a href={twLink}>→ tw: @phase1karma</a>
                            </div>
                            <div className={styles.mainMenu__contact}>
                                <span>talk</span>
                                <a href="mailto:letstalk@p1k.org">→ letstalk@p1k.org</a>
                            </div>
                        </div>
                        <div className={styles.mainMenu__copyright}>© 2020 Phase One Karma. All rights reserved</div>

                        <SocialLinks color="dark" className={styles.mainMenu__social} />
                    </div>
                </Grid>
            </Layout>
        </div>
    );
}

export const imgQuery = graphql`
    query {
        clouds: file(relativePath: { eq: "menu/clouds.png" }) {
            childImageSharp {
                fluid(maxWidth: 2732, srcSetBreakpoints: [2732], quality: 95) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                }
            }
        }
    }
`;
