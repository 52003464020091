import React, { PropsWithChildren } from 'react';

import { Grid } from 'components/grid/grid';

import styles from './header.module.scss';

export function Header(props: PropsWithChildren<{}>): JSX.Element {
    return (
        <Grid tag="header" data-main-header>
            <div className={styles.header} {...props} />
        </Grid>
    );
}
