import React from 'react';

export function LinkedinIcon(): JSX.Element {
    return (
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.71 27.04h3.38v-9.5H12.7v9.5zm1.71-14.08c-1.15 0-1.9.7-1.9 1.64 0 .91.73 1.64 1.86 1.64h.02c1.18 0 1.9-.73 1.9-1.64-.01-.93-.72-1.64-1.88-1.64zm13.83 8.63c0-2.92-1.67-4.28-3.88-4.28-1.8 0-2.6.93-3.04 1.57v-1.34h-3.38c.05.89 0 9.5 0 9.5h3.38v-5.3c0-.3.02-.58.1-.78.25-.56.8-1.15 1.74-1.15 1.22 0 1.7.87 1.7 2.15v5.08h3.38v-5.45zm-6.95-2.68l.03-.03v.04h-.03z"
                fill="currentColor"
            />
        </svg>
    );
}
