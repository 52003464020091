import React, { createContext, PropsWithChildren, useContext, useEffect, useMemo, useState } from 'react';

import { BodyLock } from 'components/body-lock/body-lock';
import { MainMenu } from 'components/main-menu/main-menu';

import styles from './modal.module.scss';

const modalsMap = {
    mainMenu: MainMenu,
};

const initCtx = {
    modal: null as keyof typeof modalsMap | null,
    setModal: (_: keyof typeof modalsMap | null) => {
        //
    },
};
const ModalContext = createContext(initCtx);

export function useModal(): typeof initCtx {
    return useContext(ModalContext);
}

export function ModalContainer(props: PropsWithChildren<{}>): JSX.Element {
    const { children } = props;
    const [show, setShow] = useState(false);
    const [modal, setModal] = useState(null as keyof typeof modalsMap | null);

    const ctx = useMemo(() => ({ modal, setModal }), [modal, setModal]);

    useEffect(() => {
        setShow(modal !== null);

        const onKeyUp = (e: KeyboardEvent) => {
            if (e.key === 'Escape' && modal !== null) {
                setModal(null);
            }
        };

        document.addEventListener('keyup', onKeyUp, { passive: true });
        return () => document.removeEventListener('keyup', onKeyUp);
    }, [modal]);

    let m = null;
    if (show && modal) {
        const ModalContent = modalsMap[modal];
        m = (
            <Modal onClick={() => setModal(null)}>
                <ModalContent />
            </Modal>
        );
    }

    return (
        <ModalContext.Provider value={ctx}>
            <>
                {modal !== null && <BodyLock />}
                {children}
                {m}
            </>
        </ModalContext.Provider>
    );
}

export function Modal(props: PropsWithChildren<{ onClick: () => void }>): JSX.Element {
    return <div className={styles['modal']} {...props} />;
}
