import { useEffect } from 'react';

import styles from './body-lock.module.scss';

export function BodyLock(): null {
    useEffect(() => {
        document.body.classList.toggle(styles.bodyLock, true);
        return () => {
            document.body.classList.toggle(styles.bodyLock, false);
        };
    }, []);

    return null;
}
