// Prevent job scrolling
module.exports = {
    shouldUpdateScroll: ({
        prevRouterProps: { location: prevLocation },
        routerProps: { location },
        getSavedScrollPosition,
    }) => {
        const prevPosition = getSavedScrollPosition(prevLocation);

        if (prevLocation.pathname.match(/^\/job\/.+/)) {
            return prevPosition;
        }

        if (location.pathname.match(/^\/job\/.+/)) {
            return prevPosition;
        }
    },
};
