import React from 'react';

export function TwitterIcon(): JSX.Element {
    return (
        <svg width="41" height="40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M29.07 14.53a7.5 7.5 0 01-2.05.52 3.4 3.4 0 001.57-1.85 7.5 7.5 0 01-2.27.82 3.67 3.67 0 00-2.6-1.06c-1.97 0-3.57 1.5-3.57 3.34 0 .27.03.52.09.77a10.38 10.38 0 01-7.36-3.5c-.3.5-.48 1.07-.48 1.68a3.3 3.3 0 001.59 2.79 3.75 3.75 0 01-1.62-.42v.04a3.4 3.4 0 002.86 3.28 3.81 3.81 0 01-1.6.06 3.55 3.55 0 003.33 2.32 7.47 7.47 0 01-5.29 1.39 10.6 10.6 0 005.47 1.5c6.57 0 10.16-5.1 10.16-9.52l-.01-.43c.7-.47 1.3-1.06 1.78-1.73z"
                fill="currentColor"
            />
        </svg>
    );
}
