import React, { PropsWithChildren } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';

import { SocialLinks } from 'components/social-links/social-links';

import styles from './footer.module.scss';

export function Footer(): JSX.Element {
    const data = useStaticQuery(imgQuery);

    return (
        <footer id="footer" className={styles.footer}>
            <FooterTop>
                <div
                    style={{ position: 'absolute', left: 0, right: 0 }}
                    data-lax
                    data-lax-anchor="self"
                    data-lax-translate-y="vh -200, 0 -50"
                >
                    <Img fluid={data.clouds.childImageSharp.fluid} loading="lazy" />
                </div>
                <Img fluid={data.plants.childImageSharp.fluid} loading="lazy" style={{ bottom: -50 }} />
            </FooterTop>
            <FooterBody>
                <FooterBodyContainer>
                    <FooterSayHello id="contacts">
                        We collaborate with ambitious brands and people. Let’s connect.{' '}
                        <a href="mailto:letscollaborate@p1k.org">
                            <span>letscollaborate@p1k.org</span>
                        </a>
                    </FooterSayHello>
                    <FooterHr />
                    <FooterContacts>
                        <FooterContact>
                            <span>write an email</span>
                            <a href="mailto:letstalk@p1k.org">letstalk@p1k.org</a>
                        </FooterContact>
                        <FooterContact>
                            <a href="mailto:hr@p1k.org">hr@p1k.org</a>
                        </FooterContact>
                    </FooterContacts>
                    <FooterSocial>
                        <span>to the point.</span>
                        <SocialLinks color="light" />
                    </FooterSocial>
                    <FooterHr />
                    <FooterUpButton />
                    <FooterCopyright>
                        <FooterLink href="/terms">Terms & Conditions</FooterLink>
                        <FooterLink href="/terms#privacy">Privacy policy</FooterLink>
                        <span>© 2020 Phase One Karma. All rights reserved</span>
                    </FooterCopyright>
                </FooterBodyContainer>
            </FooterBody>
        </footer>
    );
}

export function FooterTop(props: PropsWithChildren<{}>): JSX.Element {
    return <div className={styles.footer__top} {...props} />;
}

export function FooterBody(props: PropsWithChildren<{}>): JSX.Element {
    return <div className={styles.footer__body} {...props} />;
}

export function FooterBodyContainer(props: PropsWithChildren<{}>): JSX.Element {
    return <div className={styles.footer__bodyContainer} {...props} />;
}

export function FooterSayHello(props: PropsWithChildren<{ id?: string }>): JSX.Element {
    return <div className={styles.footer__sayHello} {...props} />;
}

export function FooterHr(): JSX.Element {
    return <div className={styles.footer__hr} />;
}

export function FooterContacts(props: PropsWithChildren<{}>): JSX.Element {
    return <div className={styles.footer__contacts} {...props} />;
}

export function FooterContact(props: PropsWithChildren<{}>): JSX.Element {
    return <div className={styles.footer__contact} {...props} />;
}

export function FooterSocial(props: PropsWithChildren<{}>): JSX.Element {
    return <div className={styles.footer__social} {...props} />;
}

export function FooterCopyright(props: PropsWithChildren<{}>): JSX.Element {
    return <div className={styles.footer__copyright} {...props} />;
}

export function FooterLink(props: PropsWithChildren<any>): JSX.Element {
    return <a className={styles.footer__link} {...props} />;
}

export function FooterUpButton(): JSX.Element {
    return (
        <div className={styles.footer__upButton}>
            <button onClick={() => window.scroll({ top: 0, behavior: 'smooth' })}>
                <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" fill="none" viewBox="0 0 56 56">
                    <path
                        fill="#60E1E0"
                        d="M38.4 26L28 15.5 17.5 26l1.9 2 7.2-7.3.2 19.7h2.7l-.2-19.7 7.2 7.2 2-1.9z"
                    />
                </svg>
            </button>
        </div>
    );
}

export const imgQuery = graphql`
    query {
        clouds: file(relativePath: { eq: "footer/clouds.png" }) {
            childImageSharp {
                fluid(maxWidth: 2732, srcSetBreakpoints: [2732], quality: 95) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                }
            }
        }
        plants: file(relativePath: { eq: "footer/plants-1.png" }) {
            childImageSharp {
                fluid(maxWidth: 2732, srcSetBreakpoints: [375, 768, 1300, 1900], quality: 95) {
                    ...GatsbyImageSharpFluid_noBase64
                }
            }
        }
    }
`;
