import React from 'react';

import { Grid } from 'components/grid/grid';
import { MenuButton } from 'components/menu-button/menu-button';

export function Aside(): JSX.Element {
    return (
        <Grid tag="aside">
            <section>
                <MenuButton type="left" />
            </section>
        </Grid>
    );
}
