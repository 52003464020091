import React from 'react';
import { useScrollbarWidth } from 'react-use';
import { Link, PageProps } from 'gatsby';
import { Helmet } from 'react-helmet';
import { AOS } from 'utils/aos';
import { getOffsetTop } from 'utils/get-offset-top';
import { GTMTag } from 'utils/seo-helper';
import { scrollbarWidthProperty } from 'constants/constants';
import { CookieBanner } from 'components/cookie-banner/cookie-banner';
import { Cursor } from 'components/cursor/cursor';
import { Footer } from 'components/footer/footer';
import { Header } from 'components/header/header';
import { HeaderMenu } from 'components/header-menu/header-menu';
import { Logo } from 'components/logo/logo';
import { ModalContainer } from 'components/modal/modal';
import styles from './base.module.scss';

type BaseLayoutProps = PageProps<{}, {
  is404Page?: boolean;
  isMainPage?: boolean;
}>;

export default function BaseLayout(props: BaseLayoutProps): JSX.Element {
  const { pageContext, location, children } = props;
  const { pathname } = location;

    const scrollbarWidth = useScrollbarWidth();
    React.useEffect(() => {
        document.body.style.setProperty(scrollbarWidthProperty, `${scrollbarWidth ?? 0}px`);
    }, [scrollbarWidth]);

  React.useEffect(() => {
    const aos = new AOS(Array.from(document.querySelectorAll<HTMLElement>('[data-aos]')));
    aos.update();

    return () => aos.destroy();
  }, [pathname]);

    React.useEffect(() => {
        let { hash } = window.location;
        if (!hash) {
            return;
        }

        hash = hash.replace(/^\/|\/$/g, '');

        try {
            const el = document.querySelector<HTMLElement>(hash);
            if (el === null) {
                return;
            }

            window.scroll({ top: getOffsetTop(el) - 100, behavior: 'smooth' });
        } catch {
        }
    }, []);

  if (pageContext.is404Page) {
    return (
      <ModalContainer>
        <div className={styles.layout}>{children}</div>
      </ModalContainer>
    );
  }

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="description" content="we create software products that have a point and a meaningful impact" />
        <meta property="og:description" content="we create software products that have a point and a meaningful impact" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://p1k.org" />
        <meta property="og:image" content="/facebook-cover.png" />
        <meta property="og:image:url" content="/facebook-cover.png" />
        <meta property="twitter:image" content="/facebook-cover.png" />
        <link rel="preconnect" href="https://www.googletagmanager.com" />
      </Helmet>

      <ModalContainer>
        <div className={styles.layout}>
          <Cursor />
          <Header>
            <Link to="/">
              <Logo />
            </Link>

            {pageContext.isMainPage && (
              <HeaderMenu />
            )}

            {!pageContext.isMainPage && (
              <Link to="/" className={styles.layout__backLink}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="19"
                  height="10"
                  fill="none"
                  viewBox="0 0 19 10"
                >
                  <path fill="currentColor"
                        d="M5 0L0 5l5 5 1-1-3.5-3.4H19V4.4H2.5L5.9.9 5 0z"
                  />
                </svg>
                <span>back to homepage</span>
              </Link>
            )}
          </Header>
          <main>{children}</main>
          <Footer />
        </div>
        <CookieBanner />

        <GTMTag code="GTM-MSFHCXM" />
      </ModalContainer>
    </>
  );
}

export function Layout(props: any): JSX.Element {
  return <div className={styles.layout} {...props} />;
}
